@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.card {
	@apply bg-white rounded-lg shadow-md p-8;
}

.form-label {
	@apply font-semibold;
}

.form-control {
	@apply border py-3 px-4 rounded-lg border-borderGrey placeholder:text-placeHolder w-full;
}

.icon {
	@apply h-6 w-6 bg-contain block bg-no-repeat bg-center;
}

.icon-30 {
	@apply bg-contain block bg-no-repeat bg-center;
	height: 30px;
	width: 30px;
}

.icon-10 {
	@apply h-3 w-3 bg-contain block bg-no-repeat bg-center;
}

.eye-slash {
	@apply bg-eye-slash;
}

.eye {
	@apply bg-eye;
}

.btn {
	@apply py-2 px-5 rounded-md flex items-center justify-center font-semibold text-base;
}

.btn-primary {
	@apply bg-primary border-primary text-white;
}

.form-link {
	@apply text-primaryMain underline uppercase font-semibold underline-offset-4;
}

.form-text {
	@apply font-semibold text-base;
}

.btn-secondary {
	@apply bg-slate-400;
}

.discountInp {
	@apply border border-dotted p-1 border-slate-400 w-full;
	min-width: 50px;
	max-width: 60px;
	line-height: 15px;
	border-color: #bbb;
}

.discountInp.short {
	max-width: 50px;
}

.discountInp.date {
	max-width: 100%;
}

table.customTable {
	border: 0;
	width: 100%;
}

table.customTable th,
table.customTable td {
	padding: 4px 8px;
}

table.customTable th {
	@apply border-b;
	color: #333;
	text-transform: capitalize;
}

table.customTable td {
	border-bottom: 1px solid #eee;
	vertical-align: top;
}

table.customTable tr:last-child td {
	border: 0;
}

table.customTable.invoiceDetail th {
	color: #666;
	font-size: 13px;
	border: 1px dotted #eee !important;
}

table.customTable.invoiceDetail td {
	font-weight: 600;
	font-size: 14px;
	border: 1px dotted #eee !important;
}

table:not(.customTable) {
	table-layout: fixed;
}

table td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

table.printPDFTable {
	table-layout: auto;
}

.checkBoxWraper span {
	padding: 0;
}
