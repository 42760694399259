@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.roboto-thin {
	font-family: "Roboto", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.roboto-light {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.roboto-regular {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.roboto-medium {
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.roboto-bold {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.roboto-black {
	font-family: "Roboto", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.table-outer {
	padding: 5px 5px 0;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.table-outer > table {
	width: 100%;
	height: 100%;
	position: relative;
	padding: 0 10px;
	table-layout: fixed;
	background-size: 100%;
	background-repeat: repeat;
}

.table-outer table {
	width: 100%;
	border-collapse: collapse;
	word-break: break-all;
}

.table-outer .printPDFTable tr th {
	color: #000;
	font-size: 10px;
	font-weight: 500;
	line-height: 1.5;
	padding: 2px 5px;
}

.table-outer tr td {
	color: #000;
	font-size: 10px;
	font-weight: 400;
	line-height: normal;
	padding: 5px;
	vertical-align: top;
	text-overflow: unset;
	white-space: nowrap;
}
.table-outer table.productDetals {
	table-layout: auto;
}
.table-outer tr.copyright td {
	color: #000;
	font-size: 8px;
	font-weight: 400;
	line-height: 10px;
	padding-top: 0;
	padding-bottom: 3px;
	vertical-align: bottom;
}

.table-outer .productDetals tr th {
	background: rgba(151, 151, 151, 0.1);
	line-height: normal;
	min-height: 21px;
	vertical-align: middle;
}

.table-outer footer tr th {
	color: #000;
	font-size: 10px;
	font-weight: 700;
	line-height: 1.5;
	padding-bottom: 0;
}

.table-outer .printPDFTable tr th tr th {
	padding: 0;
}

.table-outer .productDetals tr.amount-row > th {
	padding-bottom: 5px;
}

.tableFooter {
	position: fixed;
	bottom: 0;
	left: 20px;
	right: 20px;
}

table.printPDFTable {
	height: 100%;
}

* {
	word-wrap: break-word;
}

.table-outer tfoot table {
	table-layout: fixed;
}

@media print {
	footer {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-image: url("../../Assets/images/pdf-bg.png");
		pointer-events: none;
		background-repeat: repeat-y;
		background-repeat: no-repeat;
		background-size: 100%;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		font-size: 10px;
		line-height: normal;
	}

	.amount-row th {
		padding-top: 5px !important;
	}

	.table-outer .productDetals tr th {
		padding-bottom: 5px;
		padding-top: 5px;
		background: rgba(151, 151, 151, 0.1);
	}

	.table-outer .productDetals tr.amount-row th {
		padding-bottom: 0;
	}

	.table-outer > table {
		background-size: 100%;
		background-repeat: repeat;
	}

	.table-outer > table > thead {
		display: table-header-group !important;
	}

	.table-outer > table > tfoot {
		display: table-footer-group !important;
	}

	.table-outer > table > tbody {
		display: table-row-group !important;
		height: 250px;
	}

	.table-outer > table > tbody.creditNoteTableBody {
		display: table-row-group !important;
		height: 320px;
	}

	.table-outer > table > tbody > tr {
		page-break-inside: avoid !important;
	}

	.table-outer > table {
		page-break-inside: auto !important;
	}
}
