.d-block {
	display: block;
}

.login-card {
	width: 50%;
	margin: auto;
}

.printPDFTable ul {
	list-style: disc;
	padding: 0 0 0 14px;
}

.css-18lrjg1-MuiCircularProgress-root {
	width: 24px !important;
	height: 24px !important;
	margin-left: 10px !important;
	color: #fff !important;
}

.css-b22cb6-MuiButtonBase-root-MuiButton-root.Mui-disabled {
	color: #fff !important;
}

#main {
	background-color: #f7f7f7;
}

.paymentButtons .disabled {
	color: rgba(0, 0, 0, 0.26);
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0.12);
	pointer-events: none;
	cursor: default;
}

.exchange-btn-wrap {
	display: flex;
	align-items: end;
	justify-content: space-between;
	width: 100%;
}